import MaterialReactTable from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Navbar from '../../../components/Navbar';
import Loaders from '../../../components/Loaders';
import axios from '../../../api/axios';
import salary from '../../../assets/search.gif';
import { useForm } from 'react-hook-form';

export default function WebSearch() {
    const [result, setResult] = useState(null);
    const { register, handleSubmit } = useForm();

    const handleHistories = async () => {
        const res = await axios.get(`web-search/show`);
        return res.data.data;
    };

    const handleDatetime = (value) => {
        const timestamp = value;
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row?.search?.keyword,
                header: "Keyword",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
            },
            {
                accessorFn: (row) => row?.search?.count,
                header: "Count",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
            },
            {
                accessorFn: (row) => handleDatetime(row?.created_at),
                header: "Search Date",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
            },
        ],
        []
    );

    const postData = async (data) => {
        const response = await axios.post(`web-search/search?start_date=${data.start_date}&end_date=${data.end_date}`);
        return response.data.data;
    };

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            setResult(successData);
        },
    });

    const onSubmit = (data) => {
        mutate(data);
    };

    const { data: allHistories = [], isLoading } = useQuery({
        queryKey: ['get-histories'],
        queryFn: handleHistories,
        cacheTime: 5000,
    });
    
    return (
        <div>
            <Navbar title="Web Search Histories" />
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            )}
            <div className="container my-5">
                <div className="container">
                    <div className="card m-5">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex">
                                        <div className="m-3">
                                            <label>Start Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                {...register("start_date")}
                                                placeholder="Start Date"
                                                aria-label="Start Date"
                                            />
                                        </div>
                                        <div className="m-3">
                                            <label>End Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                {...register("end_date")}
                                                placeholder="End Date"
                                                aria-label="End Date"
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary searchButton">
                                            Search
                                        </button>
                                    </div>
                                </form>
                                <div>

                                    <img src={salary} className="salarySearch" alt="searchImage" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {allHistories && (
                    <MaterialReactTable
                        columns={columns}
                        data={result || allHistories} // Dynamically use result or fall back to allHistories
                    />
                )}
            </div>
        </div>
    );
}
