import React, { useEffect, useMemo, useState } from 'react'
import Navbar from '../../../components/Navbar'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MenuItem, Select, Switch } from '@mui/material';
import MaterialReactTable from "material-react-table";
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Loaders from '../../../components/Loaders';
export default function Employee() {
    const axiosPrivate = useAxiosPrivate();
    const [endDate, setEndDate] = useState();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState();
    const [employeeId, setEmployeeId] = useState();
    const { handleSubmit, formState } = useForm();
    const [allRole, setAllRole] = useState()
    const handleEmployee = () => {
        const res = axiosPrivate.get(`/employee/all`)
        return res;
    };
    useEffect(() => {
        getAllRole();

    }, [])

    const getAllRole = async () => {
        const res = await axiosPrivate.get(`role/all`);
        setAllRole(res?.data?.data);
        return res;
    }


    const handleChangeUserRole = async (id, e) => {
        const res = await axiosPrivate.put(`/employee/change-role?employee_id=${id}`, {
            role_id: e.target.value
        });
        return res;
    }
    const handleStartTime = (e) => {
        setStartTime(e);
    }
    const handleEndTime = (e) => {
        setEndTime(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }

    const handleEmployeeId = (id) => {
        setEmployeeId(id);
    }

    const { data: allEmployees = [], isLoading, refetch } = useQuery({
        queryKey: ['get-employee'],
        queryFn: handleEmployee,
        cacheTime: 5000,
    })

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const postData = async (data) => {
        const response = axiosPrivate.put('employee/shift/update', {
            start_time: startTime,
            end_time: endTime,
            employee_id: employeeId,
            end_date: endDate,
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            // reload window
            refetch();
        }
    })
    const onSubmit = (data) => {

        mutate(data);
    };

    const handleRemoveEmployee = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {

                const res = axiosPrivate.delete(`/employee/delete?employee_id=${id}`)
                res.then(() => {
                    swalWithBootstrapButtons.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                    refetch()
                })

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Customer is Now Default Mode :)',
                    'error'
                )
            }
        })

    }

    const handleEmployeeStatus = (id, e) => {
        const res = axiosPrivate.put(`/employee/access/update`, {
            employee_id: id,
            is_active: e
        })
        res.then(() => {
            refetch()
        })
    }

    const columns = useMemo(
        () => [
            {

                accessorFn: (row) => <>

                    {row?.image ? <img src={row?.image} alt="images" className='w-25' /> : <h1><i className="fa-regular fa-image w-50" style={{ color: "#3119e6", }} ></i></h1>}
                </>,
                id: "image", //id required if you use accessorFn instead of accessorKey
                header: "Image",
                Header: <i style={{ color: "#6e4695" }}>Image</i> //optional custom markup

            },
            {
                accessorKey: "name", //simple recommended way to define a column
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => row?.phone, //alternate way
                id: "phone", //id required if you use accessorFn instead of accessorKey
                header: "Phone Number",
                Header: <i style={{ color: "#6e4695" }}>Phone Number</i> //optional custom markup
            },
            {
                accessorFn: (row) => <>
                    <>
                        <Select
                            onChange={(e) => handleChangeUserRole(row?.id, e)}
                            defaultValue={row?.role_id !== null ? row?.role_id : "Select User Role"}
                        >
                            <MenuItem hidden value="Select User Role">Select User Role</MenuItem>
                            {
                                allRole?.map((role) => <MenuItem value={role?.id}>{role?.name}</MenuItem>)
                            }
                            {/* <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="packer">Packer</MenuItem> */}
                        </Select>
                    </>
                </>, //alternate way
                id: "role", //id required if you use accessorFn instead of accessorKey
                header: "User Type",
                Header: <i style={{ color: "#6e4695" }}>User Type</i> //optional custom markup
            },
            {
                accessorFn: (row) => <Switch defaultChecked={row.is_active} onChange={(e) => handleEmployeeStatus(row.id, e.target.checked)} />,
                id: "is_active",
                header: "Status",
                Header: <i style={{ color: "#6e4695" }}>Status</i>
            },

            {
                accessorFn: (row) => <div>
                    <div data-bs-toggle="modal" className='shift-modal' data-bs-target={`#exampleModal-${row.id}`} onClick={() => handleEmployeeId(row.id)}>
                        <p>{row?.working_shift?.end_date ? moment(row?.working_shift?.end_date).format('dddd,MMM Do,YYYY') : "Not Assign Date"}</p>
                        <p>{(row?.working_shift?.start_time || row?.working_shift?.end_time) ? (moment(row?.working_shift?.start_time, "HH:mm:ss").format('hh:mm A') + "-" + (moment(row?.working_shift?.end_time, "HH:mm:ss").format('hh:mm A'))) : "Not Assign Shift"}</p>
                    </div>

                    <div className="modal fade" id={`exampleModal-${row.id}`} tabIndex="-1" aria-labelledby={`exampleModalLabel-${row.id}`} aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id={`exampleModalLabel-${row.id}`}>Working Shift</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            <div className='d-flex justify-content-between mt-3 align-items-center'>
                                                <p className='text-purple'> <i className="fa-solid fa-calendar-week"></i> Date: {row?.working_shift?.end_date ? moment(row?.working_shift?.end_date).format('dddd,MMM Do,YYYY') : "Not Assign"}</p>
                                                <input type="date" id='end_date' name='end_date' onBlur={
                                                    (e) => {
                                                        handleEndDate(e.target.value)
                                                    }
                                                } className='form-control input-width' />
                                            </div>

                                            <div className='d-flex justify-content-between my-3 align-items-center'>
                                                <p className='text-purple'><i className="fa-regular fa-clock"></i> Start Time: {row?.working_shift?.end_time ? moment(row?.working_shift?.start_time, "HH:mm:ss").format('hh:mm A') : "Not Assign"}</p>
                                                <input type="time" id='start_time' name='start_time' className='form-control input-width' onBlur={

                                                    (e) => handleStartTime(e.target.value)
                                                } />
                                                {formState.errors.start_time && <p>{formState.errors.start_time.message}</p>}
                                            </div>

                                            <div className='d-flex justify-content-between mb-3 align-items-center'>
                                                <p className='text-purple'><i className="fa-regular fa-clock"></i> End Time: {row?.working_shift?.end_time ? moment(row?.working_shift?.end_time, "HH:mm:ss").format('hh:mm A') : "Not Assign"}</p>
                                                <input type="time" id='end_time' className='form-control input-width' name='end_time' onBlur={(e) => handleEndTime(e.target.value)} />
                                            </div>
                                            <input type="submit" className="btn btn-login" data-bs-dismiss="modal" value="submit" />
                                        </div>
                                        {/* <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="submit" className="btn btn-primary">Save changes</button>
                                        </div> */}
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>, //alternate way
                id: "Shift", //id required if you use accessorFn instead of accessorKey
                header: "Shift",
                Header: <i style={{ color: "#6e4695" }}>Working Shift</i> //optional custom markup
            },

            {
                accessorFn: (row) => <div>
                    <button onClick={() => handleRemoveEmployee(row.id)} className='btn btn-danger mx-2 text-white fs-6'><i className="fa-solid fa-trash-can"></i></button>
                    <Link to={`/admin/dashboard/employees/details/${row.id}`} className='btn btn-success text-white fs-6'><i className="fa-solid fa-eye"></i></Link>
                </div>,
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //optional custom markup


            }
        ],
        [allRole]
    );
    return (
        <>
            <Navbar
                title="Employees"
                btnTitle="Add New Employee"
                btnLink="/admin/dashboard/employees/create"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }
            <div className='container my-5'>
                {allEmployees?.data?.data && <MaterialReactTable

                    columns={columns}
                    data={allEmployees?.data?.data} />
                }

            </div>
        </>
    )
}
