import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import Loaders from '../../../components/Loaders';
import { Link } from 'react-router-dom';

export default function ProductTypes() {

    const axiosPrivate = useAxiosPrivate();

    const handleProductTypes = () => {
        const res = axiosPrivate.get(`product-type/all`)
        return res;
    };


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row.name,
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.description,
                header: "Description",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => (
                    <>
                        <Link to={`/admin/dashboard/product-types/edit/${row.id}`} className='btn btn-success '><i className="fa-regular fa-pen-to-square"></i></Link>
                    </>
                ),
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //
            }
        ],
        [],
    );

    const { data: allProductTypes = [], isLoading, refetch } = useQuery({
        queryKey: ['get-ProductTypes'],
        queryFn: handleProductTypes,
        cacheTime: 5000,
    })
    return (
        <>
            <Navbar
                title="Product Types"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }



            <div className='container my-5'>


                {allProductTypes?.data?.data &&
                    <>
                        <MaterialReactTable
                            columns={columns}
                            data={allProductTypes?.data?.data}
                        />
                    </>
                }
            </div>
        </>
    )
}
