import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function UpdatePromotion() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch, getValues } = useForm();
    const params = useParams();
    const [promotionImage, setPromotionImage] = useState(null);
    const [image, setImage] = useState(null);
    const postData = async (data) => {
        const makeData = {
            name: data.name,
            campaign_type: data.campaign_type,
            coupon_type: data.coupon_type,
            coupon_value: data.coupon_value,
            is_active: data.is_active,
            image: promotionImage ? promotionImage : null
        }

        const response = axiosPrivate.put(`promotional-campaign/update/${params.id}`, makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/promotions'
                }, 3000)
            }
        }
    })

    const handlePromotion = () => {
        const res = axiosPrivate.get(`promotional-campaign/details/${params.id}`)
        return res;
    }

    const handleFile = (event) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();


        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setPromotionImage(reader?.result);
        };
    }


    const onSubmit = (data) => {
        mutate(data);
    }

    const { data: promotion } = useQuery({
        queryKey: ['get-promotion'],
        queryFn: handlePromotion
    })


    useEffect(() => {
        if (promotion?.data?.data) {
            Object.keys(promotion.data.data).forEach((key) => {
                setValue(key, promotion.data.data[key]);
            });

            setImage(promotion.data.data.image)
        }
    }, [promotion, setValue])
    return (
        <>
            <Navbar
                title="Update a Promotion"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    placeholder="Enter Promotion Name"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("name")}
                                    error={!!errors?.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Campaign Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("campaign_type")}
                                        value={watch("campaign_type") || ""}
                                        label="Campaign Type"
                                        onChange={(event) => {
                                            setValue("campaign_type", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="coupon">Coupon</MenuItem>
                                    </Select> </FormControl>
                            </Grid>

                            {((getValues('campaign_type') === 'coupon')) &&
                                <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                            <InputLabel id="demo-simple-select-label">Coupon type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="type"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...register("coupon_type")}
                                                value={watch("coupon_type") || ""}
                                                label="Coupon type"
                                                onChange={(event) => {
                                                    setValue("coupon_type", event.target.value)
                                                }}
                                            >

                                                <MenuItem value="percentage">Percentage</MenuItem>
                                            </Select> </FormControl>

                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            id="coupon_value"
                                            label="Coupon value"
                                            variant="outlined"
                                            placeholder="Enter Coupon value"
                                            fullWidth={true}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("coupon_value")}
                                            error={!!errors?.coupon_value}
                                            helperText={errors.coupon_value?.message}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={6}>
                                <img src={promotionImage ? promotionImage : image} alt='' height="100px" />
                            </Grid>



                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Flag Image</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={handleFile} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("is_active")}
                                        value={watch("is_active") || ""}
                                        label="Status"
                                        onChange={(event) => {
                                            setValue("is_active", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={"1"}> Active</MenuItem>
                                        <MenuItem value={"0"}> Inactive</MenuItem>
                                    </Select> </FormControl>
                            </Grid>
                            <Grid item xs={6}></Grid>


                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
