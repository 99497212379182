import { Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Navbar from '../../../../components/Navbar'
import Loaders from '../../../../components/Loaders'
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import './DurationPicker.css';

export default function SalaryApprovalEdit() {
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const [attendanceDetails, setAttendanceDetails] = useState({});

    const [checkInTime, setCheckInTime] = useState(0)
    const [checkOutTime, setCheckOutTime] = useState(0)

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [lunchMinute, setLunchMinute] = useState(0)

    const [b2cHours, setB2cHours] = useState(0);
    const [b2cMinutes, setB2cMinutes] = useState(0);
    const [b2bHours, setB2bHours] = useState(0);
    const [b2bMinutes, setB2bMinutes] = useState(0);
    const [prePackedHours, setPrepackedHours] = useState(0);
    const [prePackedMinutes, setPrePackedMinutes] = useState(0);
    const [administrationMinutes, setAdministrationMinutes] = useState(0)
    const [administrationHours, setAdministrationHours] = useState(0)

    const handleHoursChange = (e) => {
        setHours(parseInt(e.target.value, 10));
    };

    useEffect(() => {
        calculateStartAndEndTimeDuration()
    }, [checkInTime, checkOutTime, lunchMinute])

    const handleMinutesChange = (e) => {

        setMinutes(parseInt(e.target.value, 10));
    };


    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const handleattendanceDetails = () => {
        const res = axiosPrivate.get(`attendance/attendance-details/${params.id}`)
        return res;
    };


    const { isLoading, isError, error } = useQuery({
        queryKey: 'attendanceDetails',
        queryFn: handleattendanceDetails,
        staleTime: 0,
        refetchInterval: false,
        onSuccess: (data) => {
            setAttendanceDetails(data?.data?.data)
        }
    })

    const postData = async (data) => {
        const makeData = {
            check_in_time: checkInTime,
            check_out_time: checkOutTime,
            attendance_date: data.attendance_date,
            pause_time: lunchMinute,
            b2b_time: data.b2b_time,
            b2c_time: data.b2c_Time,
            prepacked_time: data.prepacked_time,
            administration_time: data.administration_time,
            work_minutes_total: data.work_minutes_total,
            is_approved: data.type === true ? true : false
        }



        const response = axiosPrivate.put(`attendance/attendance-update/${params.id}`, makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/salary-approval'
                }, 2000)
            }
        }
    })

    const calculateTimeHourMinute = (value) => {
        const hours = (value / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const hour = rhours < 10 ? `0${rhours}` : rhours;
        const minute = rminutes < 10 ? `0${rminutes}` : rminutes;

        return {
            hour,
            minute
        }

    }

    const handleTimeHourMinute = () => {
        // value to hour and minute
        setCheckInTime(attendanceDetails?.check_in_time)
        setCheckOutTime(attendanceDetails?.check_out_time)
        if (attendanceDetails.work_minutes_total) {
            const calucalteTime = calculateTimeHourMinute(attendanceDetails.work_minutes_total)
            setHours(parseInt(calucalteTime.hour), 10)
            setMinutes(parseInt(calucalteTime.minute), 10)
        }

        if (attendanceDetails.b2c_time) {
            const b2cCalulateTime = calculateTimeHourMinute(attendanceDetails.b2c_time)
            setB2cHours(parseInt(b2cCalulateTime.hour), 10)
            setB2cMinutes(parseInt(b2cCalulateTime.minute), 10)
        }

        if (attendanceDetails.b2b_time) {
            const b2bCalculateTime = calculateTimeHourMinute(attendanceDetails.b2b_time)
            setB2bHours(parseInt(b2bCalculateTime.hour), 10)
            setB2bMinutes(parseInt(b2bCalculateTime.minute), 10)
        }

        if (attendanceDetails.prepacked_time) {
            const prepaCaculateTime = calculateTimeHourMinute(attendanceDetails.prepacked_time)
            setPrepackedHours(parseInt(prepaCaculateTime.hour), 10)
            setPrePackedMinutes(parseInt(prepaCaculateTime.minute), 10)
        }

        if (attendanceDetails.administration_time) {
            const administrationCalculateTime = calculateTimeHourMinute(attendanceDetails.administration_time)
            setAdministrationHours(parseInt(administrationCalculateTime.hour), 10)
            setAdministrationMinutes(parseInt(administrationCalculateTime.minute), 10)
        }

        if (attendanceDetails.pause_time) {
            setLunchMinute(attendanceDetails.pause_time)
        }
    }

    const calculateStartAndEndTimeDuration = () => {
        const parseTime = (timeString) => {
            const [hours, minutes] = timeString?.split(":")?.map(Number);
            return new Date(0, 0, 0, hours, minutes);
        };
        if (checkInTime && checkOutTime) {
            const startTime = parseTime(checkInTime);
            const endTime = parseTime(checkOutTime);
            if (startTime && endTime) {
                const timeDifference = endTime - startTime;
                const totalMinutes = timeDifference / (1000 * 60);
                const netMinutes = totalMinutes - lunchMinute;
                if (netMinutes < 0) {
                    setCheckInTime(attendanceDetails?.check_in_time)
                    setCheckOutTime(attendanceDetails?.check_out_time)
                    return Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Time Duration Invaild",
                    });


                }

                const hours = Math.floor(netMinutes / 60);
                const minutes = Math.floor(netMinutes % 60);
                setHours(hours)
                setMinutes(minutes)
            }
        }
    }

    useEffect(() => {
        Object.keys(attendanceDetails).forEach((key) => {
            setValue(key, attendanceDetails[key]);
        });


        handleTimeHourMinute()
    }, [attendanceDetails, setValue])


    function calculateTotalMinutes(hours, minutes) {
        return (hours * 60) + minutes;
    }

    const onSubmit = (data, type) => {
        data.b2b_time = calculateTotalMinutes(b2bHours, b2bMinutes)
        data.b2c_Time = calculateTotalMinutes(b2cHours, b2cMinutes)
        data.prepacked_time = calculateTotalMinutes(prePackedHours, prePackedMinutes)
        data.administration_time = calculateTotalMinutes(administrationHours, administrationMinutes)
        data.work_minutes_total = calculateTotalMinutes(hours, minutes)
        data.type = type

        if (data.work_minutes_total !== (data.b2b_time + data.b2c_Time + data.prepacked_time + data.administration_time)) {
            return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Time duration is not equal to total duration of time",
            });
        }


        mutate(data);
    }
    return (
        <>
            <Navbar
                title={`Update Attendance Info of  ${attendanceDetails?.employee?.name}`}
            />
            {
                isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div> : isError ? <div>{error}</div> : <div></div>
            }
            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="code1"
                                    label="Date"
                                    variant="outlined"
                                    placeholder="Enter Date"
                                    type='date'
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    {...register("attendance_date")}

                                />

                            </Grid>
                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>Duration:</label>
                                    <div className="duration-select-container">
                                        <select className="duration-select" disabled value={hours} defaultValue={hours} onChange={handleHoursChange}>
                                            {[...Array(24).keys()].map((hour) => (
                                                <option key={hour} value={hour} >
                                                    {hour} {hour === 1 ? 'hour' : 'hours'}
                                                </option>
                                            ))}
                                        </select>

                                        <select className="duration-select" disabled value={minutes} onChange={handleMinutesChange}>
                                            {[...Array(60).keys()].map((minute) => (

                                                <option key={minute} value={minute}>

                                                    {minute} {minute === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {hours} {hours === 1 ? 'hour' : 'hours'}, {minutes} {minutes === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>
                            {/* 
                            <Grid item xs={6}>
                                <TextField
                                    id="value1"
                                    label="Duration"
                                    variant="outlined"
                                    placeholder="Enter Duration"
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("work_minutes_total")}
                                    error={Boolean(errors?.work_minutes_total)}
                                    helperText={errors.work_minutes_total ? errors.value?.message : ""}
                                />

                            </Grid> */}
                            <Grid item xs={6}>
                                <TextField
                                    id="ambassador_percentage3"
                                    label="Start Time"
                                    variant="outlined"
                                    placeholder="Start Time"
                                    type='time'
                                    margin="normal"
                                    fullWidth={true}
                                    value={checkInTime}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setCheckInTime(e.target?.value)}
                                    error={Boolean(errors?.check_in_time)}
                                    helperText={errors.check_in_time ? errors.check_in_time?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>B2C Time:</label>
                                    <div className="duration-select-container">
                                        <select className="duration-select" value={b2cHours} onChange={(e) => setB2cHours(parseInt(e.target?.value, 10))}>
                                            {[...Array(24).keys()].map((b2cHour) => (
                                                <option key={b2cHour} value={b2cHour}>
                                                    {b2cHour} {b2cHour === 1 ? 'hour' : 'hours'}
                                                </option>
                                            ))}
                                        </select>

                                        <select className="duration-select" value={b2cMinutes} onChange={(e) => setB2cMinutes(parseInt(e.target?.value, 10))}>
                                            {[...Array(60).keys()].map((b2cMinute) => (

                                                <option key={b2cMinute} value={b2cMinute}>

                                                    {b2cMinute} {b2cMinute === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {b2cHours} {b2cHours === 1 ? 'hour' : 'hours'}, {b2cMinutes} {b2cMinutes === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="ambassador_percentage3"
                                    label="End Time"
                                    variant="outlined"
                                    placeholder="End Time"
                                    type='time'
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={checkOutTime}
                                    onChange={(e) => setCheckOutTime(e.target?.value)}
                                    error={Boolean(errors?.check_in_time)}
                                    helperText={errors.check_in_time ? errors.check_in_time?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>Prepacked Time:</label>
                                    <div className="duration-select-container">
                                        <select className="duration-select" value={prePackedHours} onChange={(e) => setPrepackedHours(parseInt(e.target.value, 10))}>
                                            {[...Array(24).keys()].map((prePackedHour) => (
                                                <option key={prePackedHour} value={prePackedHour}>
                                                    {prePackedHour} {prePackedHour === 1 ? 'hour' : 'hours'}
                                                </option>
                                            ))}
                                        </select>

                                        <select className="duration-select" value={prePackedMinutes} onChange={(e) => setPrePackedMinutes(parseInt(e.target.value, 10))}>
                                            {[...Array(60).keys()].map((prePackedMinute) => (

                                                <option key={prePackedMinute} value={prePackedMinute}>

                                                    {prePackedMinute} {prePackedMinute === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {prePackedHours} {prePackedHours === 1 ? 'hour' : 'hours'}, {prePackedMinutes} {prePackedMinutes === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>



                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>Lunch Time:</label>
                                    <div className="duration-select-container">


                                        <select className="duration-select" value={lunchMinute} onChange={(e) => setLunchMinute(parseInt(e.target.value, 10))}>
                                            {[...Array(60).keys()].map((lunchTime) => (

                                                <option key={lunchTime} value={lunchTime}>

                                                    {lunchTime} {lunchTime === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {lunchMinute} {lunchMinute === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>B2B Time:</label>
                                    <div className="duration-select-container">
                                        <select className="duration-select" value={b2bHours} onChange={(e) => setB2bHours(parseInt(e.target.value, 10))}>
                                            {[...Array(24).keys()].map((b2bHour) => (
                                                <option key={b2bHour} value={b2bHour}>
                                                    {b2bHour} {b2bHour === 1 ? 'hour' : 'hours'}
                                                </option>
                                            ))}
                                        </select>

                                        <select className="duration-select" value={b2bMinutes} onChange={(e) => setB2bMinutes(parseInt(e.target.value, 10))}>
                                            {[...Array(60).keys()].map((b2bMinute) => (

                                                <option key={b2bMinute} value={b2bMinute}>

                                                    {b2bMinute} {b2bMinute === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {b2bHours} {b2bHours === 1 ? 'hour' : 'hours'}, {b2bMinutes} {b2bMinutes === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>


                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <div className="duration-picker-container">
                                    <label>Administration Time:</label>
                                    <div className="duration-select-container">
                                        <select className="duration-select" value={administrationHours} onChange={(e) => setAdministrationHours(parseInt(e.target.value, 10))}>
                                            {[...Array(24).keys()].map((administrationHours) => (
                                                <option key={administrationHours} value={administrationHours}>
                                                    {administrationHours} {administrationHours === 1 ? 'hour' : 'hours'}
                                                </option>
                                            ))}
                                        </select>

                                        <select className="duration-select" value={administrationMinutes} onChange={(e) => setAdministrationMinutes(parseInt(e.target.value, 10))}>
                                            {[...Array(60).keys()].map((administrationMinutes) => (

                                                <option key={administrationMinutes} value={administrationMinutes}>

                                                    {administrationMinutes} {administrationMinutes === 1 ? 'minute' : 'minutes'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="selected-duration">Selected Duration: {administrationHours} {administrationHours === 1 ? 'hour' : 'hours'}, {administrationMinutes} {administrationMinutes === 1 ? 'minute' : 'minutes'}</p>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white mx-3'
                                    type="submit"

                                >Submit</button>

                                <button
                                    className='btn btn-info text-dark'
                                    onClick={handleSubmit((data) => onSubmit(data, true))}
                                    type='button'
                                >Submit with approve</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >

        </>
    )
}
