import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import Loaders from '../../../components/Loaders';
import { Link } from 'react-router-dom';
import { Switch } from '@mui/material';

export default function OrderTypes() {
    const axiosPrivate = useAxiosPrivate();


    const handleOrderTypes = () => {
        const res = axiosPrivate.get(`order-type/all`)
        return res;
    };
    const handleOrderTypeStatus = async (id, value) => {
        const response = await axiosPrivate.put(`order-type/is_active/${id}`, {
            status: value
        });
        refetch()
        return response;
    }


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => <img src={row.images.en} alt="Images" height='50px' />,
                header: "Image",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.label.en,
                header: "Label",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.order_type,
                header: "Order type",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },

            {
                accessorFn: (row) => <Switch defaultChecked={row.is_active} onChange={(e) => handleOrderTypeStatus(row.id, e.target.checked)} />,
                header: "Status",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => (
                    <>
                        <Link to={`/admin/dashboard/order-types/edit/${row.id}`} className='btn btn-success '><i className="fa-regular fa-pen-to-square"></i></Link>
                    </>
                ),
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //
            }
        ],
        [],
    );

    const { data: allOrderTypes = [], isLoading, refetch } = useQuery({
        queryKey: ['get-Order-type'],
        queryFn: handleOrderTypes,
        cacheTime: 5000,
    })
    return (
        <>
            <Navbar
                title="Order Types"
                btnTitle="Add New Order Types"
                btnLink="/admin/dashboard/order-types/create"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }



            <div className='container my-5'>


                {allOrderTypes?.data?.data &&
                    <>
                        <MaterialReactTable
                            columns={columns}
                            data={allOrderTypes?.data?.data}
                        />
                    </>
                }
            </div>
        </>
    )

}
