import React, {  useMemo } from 'react'
import Navbar from '../../../components/Navbar'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from 'material-react-table';
import { useQuery } from '@tanstack/react-query';
import Loaders from '../../../components/Loaders';
import RefundModal from '../../../components/RefundModal';


export default function Orders() {
    const axiosPrivate = useAxiosPrivate();
    const handleOrder = () => {
        const res = axiosPrivate.get(`order/all`)
        return res;
    };
    const getOrderStatus = (row) => {
        if (row.status === 0) {
            return <span class="badge bg-danger">Canceled</span>;
        } else if (row.status === 1) {
            return <span class="badge bg-primary">Not paid / Waiting for payment</span>;
        } else if (row.status === 2) {
            return <span class="badge bg-warning text-dark">Paid - Waiting other reason</span>;
        } else if (row.status === 3 && row.order_tracking_link === null && !row.payment_status) {
            return <span class="badge bg-info text-dark">Paid - Synced with Shopify/packing app</span>;
        } else if ((row.status === 3 && row.order_tracking_link && row.refund_amount == null) || (row.status === 3 && row.payment_status === 'captured' && row.refund_amount == null)) {
            return <span class="badge bg-success">Paid - Packed/shipped</span>;
        }

        else if ((row.status === 3 && row.order_tracking_link && row.refund_amount !== null) || (row.status === 3 && row.payment_status === 'captured' && row.refund_amount !== null)) {
            return <span class="badge bg-purple">Paid - Packed/shipped (Refund)</span>;
        }
        // Default status if none of the conditions match
        return "Unknown";
    };
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "order_tracking_id",
                header: "Order Tracking Id",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => renderedCellValue
            },
            {
                accessorFn: (row) => getOrderStatus(row),
                header: "Order status",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.payment_method ? (row.payment_method === 'mobilepay' ? <span class="badge rounded-pill bg-primary">MobilePay</span> : <span class="badge rounded-pill bg-secondary"> QuickPay</span>) : <span class="badge rounded-pill bg-danger"> N/A</span>,
                id: "paymentMethod",
                header: "Payment method",
                Header: <i style={{ color: "#9C6EAA" }}>Payment method</i>
            },
            {
                accessorFn: (row) => {
                    return <>
                        <RefundModal row={row} refetch={refetch} />
                    </>

                },
                id: "action",
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i>
            }
        ]
    }, []
    );

    const { data: allOrders = [], isLoading, refetch } = useQuery({
        queryKey: ['get-customers'],
        queryFn: handleOrder,
        refetchInterval: 20000,
        refetchOnWindowFocus: true,
        cacheTime: 5000,
    })
    return (
        <>
            <Navbar
                title="Orders"
            />

            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }

            <div className='container my-5'>
                {allOrders?.data?.data && <MaterialReactTable
                    columns={columns}
                    data={allOrders?.data?.data}
                />}
            </div>
        </>
    )
}
