
import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Clock from 'react-clock';
import axios from '../../../api/axios';
import Navbar from '../../../components/Navbar';
import 'react-clock/dist/Clock.css';
import Loaders from '../../../components/Loaders';

import attention from '../../../assets/attention.png'

const Home = () => {
    const [avgTime, setAvgTime] = useState();
    const [value, setValue] = useState(new Date());
    const handleOrderStatistic = () => {
        const res = axios.get(`order/statistic`)
        return res;
    };

    const handleRating = () => {
        const res = axios.get(`dashboard/statistic/rating`)
        return res;
    };

    const convertPointToComma = (value) => {
        return value?.toString().replace('.', ',')
    }

    const [statisticQuery, RatingQuery] = useQueries({
        queries: [
            {
                queryKey: 'get-Statistic',
                queryFn: handleOrderStatistic,
                cacheTime: 5000,
            },
            {
                queryKey: 'get-rating',
                queryFn: handleRating,
                refetchInterval: 50000,
                refetchOnWindowFocus: true,
            }

        ]

    })


    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);




    useEffect(() => {
        const timeStr = statisticQuery?.data?.data?.data?.avg_packed_time === "0" ? "00:00" : statisticQuery?.data?.data?.data?.avg_packed_time || "00:00";
        const minutes = timeStr?.slice(0, 2);
        const seconds = timeStr?.slice(3, 5);
        const formattedTime = `${minutes}:${seconds}`;
        setAvgTime(formattedTime);

    }, [statisticQuery?.data])

    return (
        <div>
            <Navbar
                title="Dashboard"
            />
            {
                statisticQuery?.isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
                    :
                    <div >
                        <div className='card mx-5 my-2'>
                            <h3 className='card-header-color-rating-dashboard bg-white'>B2C Order</h3>
                            <div className='d-flex justify-content-evenly p-2'>
                                <div className="card card-packing-home">
                                    <h5 className="card-header card-header-color-rating-dashboard ">Open orders</h5>
                                    <div className="card-body">
                                        <h1 className='card-count-rating-home'>{statisticQuery?.data?.data?.data?.open_orders}</h1>
                                    </div>
                                </div>
                                <div className="card card-packing-home">
                                    <h5 className="card-header card-header-color-rating-dashboard">B2C Packaged today</h5>
                                    <div className="card-body">
                                        <h1 className='card-count-rating-home'>{statisticQuery?.data?.data?.data?.packed_today}</h1>
                                    </div>
                                </div>

                                <div className="card card-packing-rating-home">
                                    <h5 className="card-header card-header-color-rating-dashboard">Order Cost B2C</h5>
                                    <div className="card-body card-body-wrapper">
                                        <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.b2c_differance > 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.b2c_differance < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.b2c_differance)}%</div>
                                        <h1 className='card-count-rating-home'>{convertPointToComma(RatingQuery?.data?.data?.data?.cost_per_package_30)}</h1>
                                        <h6 className='kr'>(kr)</h6>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div>
                            <div className='card mx-5 my-2'>
                                <h3 className='card-header-color-rating-dashboard bg-white '>Prepacked Order</h3>
                                <div className='d-flex justify-content-evenly'>
                                    <div className="card card-packing-home">
                                        <h5 className="card-header card-header-color-rating-dashboard "> Open orders</h5>
                                        <div className="card-body">
                                            <h1 className='card-count-rating-home'>{RatingQuery?.data?.data?.data?.prepacked_open_order?.shipment_count}</h1>
                                            <h6 className='text-warning '>(Value: {RatingQuery?.data?.data?.data?.prepacked_open_order?.order_value} kr) {RatingQuery?.data?.data?.data?.prepacked_open_order?.needAttention ? <img src={attention} className='attention-image' alt='Mix ' data-bs-toggle="tooltip" data-bs-placement="top" title="Mix recipe price is missing" /> : ''}</h6>
                                        </div>
                                    </div>
                                    <div className="card card-packing-home">
                                        <h5 className="card-header card-header-color-rating-dashboard ">Prepacked Shipment(30 days)</h5>
                                        <div className="card-body">
                                            <h1 className='card-count-rating-home'>{RatingQuery?.data?.data?.data?.total_prepacked_quantity_30_month_data}</h1>
                                        </div>
                                    </div>
                                    <div className="card card-packing-rating-home">
                                        <h5 className="card-header card-header-color-rating-dashboard">Unit Cost Prepacked</h5>
                                        <div className="card-body card-body-wrapper">
                                            <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.prepacked_difference > 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.prepacked_difference < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.prepacked_difference)}%</div>
                                            <h1 className='card-count-rating-home'>{convertPointToComma(RatingQuery?.data?.data?.data?.cost_per_package_prepacked_30_days)}</h1>
                                            <h6 className='kr'>(kr)</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>

            }


        </div >
    );
};

export default Home;