import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import MaterialReactTable from "material-react-table";
import Navbar from '../../../components/Navbar';
import { MenuItem, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loaders from '../../../components/Loaders';
import { useForm } from 'react-hook-form';

const Product = () => {
    const axiosPrivate = useAxiosPrivate();
    const handleCandy = () => {
        const res = axiosPrivate.get(`candy/all?country_code=dk`)
        return res;
    };
    const { register, handleSubmit } = useForm();
    const postData = async (data) => {
        const response = axiosPrivate.post(`candy/create/combo-items?shopify_product_id=${data.shopify_product_id}`);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            refetch()

        }
    })

    const onSubmit = (data) => {
        mutate(data);
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleChangeAlley = async (id, e) => {
        const res = await axiosPrivate.put(`candy/change-alley`, {
            candy_id: id,
            alley_side: e.target.value
        });

        if (res.status === 200) {

        }
    }

    const handleChangeStatus = async (id, e) => {
        const res = await axiosPrivate.put(`candy/change-status`, {
            candy_id: id,
            status_code: e.target.value
        });

        if (res.status === 200) {

        }
    }
    const handleProductDelete = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosPrivate.delete(`candy/delete?candy_id=${id}`);
                res.then(() => {
                    swalWithBootstrapButtons.fire(
                        'Deleted!',
                        'Your Candy has been deleted.',
                        'success'
                    )
                    refetch();
                })

            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })

    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "name", //simple recommended way to define a column
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } }, //custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => row?.short_code, //alternate way
                id: "short_code", //id required if you use accessorFn instead of accessorKey
                header: "Shrot Code",
                Header: <i style={{ color: "#9C6EAA" }}>Shrot Code</i> //optional custom markup
            },
            {
                accessorFn: (row) =>
                    <>
                        <Select
                            onChange={(e) => handleChangeStatus(row?.id, e)}
                            defaultValue={row?.pricing_info?.product_status !== null ? row?.pricing_info?.product_status : "Select Status"}
                        >
                            <MenuItem hidden value="Select Status">Select Status</MenuItem>
                            <MenuItem value="0">Draft</MenuItem>
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="2">Archived</MenuItem>
                        </Select>
                    </>,
                id: "product_status", //id required if you use accessorFn instead of accessorKey
                header: "Status",
                Header: <i style={{ color: "#9C6EAA" }}>Status</i> //optional custom markup
            },
            {
                accessorFn: (row) => <>
                    <Select
                        onChange={(e) => handleChangeAlley(row?.id, e)}
                        defaultValue={row?.alley_side !== null ? row?.alley_side : "Select Alley"}
                    >
                        <MenuItem hidden value="Select Alley">Select Alley</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                        <MenuItem value="right">Right</MenuItem>
                    </Select>
                </>, //alternate way
                id: "alley", //id required if you use accessorFn instead of accessorKey
                header: "Alley",
                Header: <i style={{ color: "#9C6EAA" }}>Alley</i> //optional custom markup

            },
            {
                accessorFn: (row) => <>
                    {row?.image ? <img src={row?.image} alt="images" className='w-25' /> : <h1><i className="fa-regular fa-image w-50" style={{ color: "#3119e6", }} ></i></h1>}
                </>,
                id: "image", //id required if you use accessorFn instead of accessorKey
                header: "Image",
                Header: <i style={{ color: "#9C6EAA" }}>Image</i> //optional custom markup

            },
            {
                accessorFn: (row) => <div>
                    <Link to={`/admin/dashboard/product/edit/${row.id}`} className='btn btn-success '><i className="fa-regular fa-pen-to-square"></i></Link>
                    <button onClick={() => handleProductDelete(row.id)} className='btn btn-danger mx-2'><i className="fa-solid fa-trash" ></i></button>
                </div>,
                id: "action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "red" }}>Action</i> //optional custom markup


            }
        ],
        []
    );

    const { data: allCandy = [], isLoading, error, isFetching, refetch } = useQuery({
        queryKey: ['get-candy'],
        queryFn: handleCandy,
        cacheTime: 5000,
    })

    return (
        <div>

            <Navbar
                title="Candies of SlikEkspressen"
                btnTitle="Add New Candy"
                btnLink="/admin/dashboard/product/create"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }

            <div className='container my-5'>
                {allCandy?.data?.data && <>
                    <button type="button" className="btn btn-info float-end" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Add product From Shopify</button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Shopify Product Import</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3">
                                            <label htmlFor="recipient-name" className="col-form-label">Shopify product id:</label>
                                            <input type="text" className="form-control" id="recipient-name" {...register("shopify_product_id")} />
                                        </div>
                                        <input type="submit" className="btn btn-login" data-bs-dismiss="modal" value="submit" />

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                    <MaterialReactTable
                        columns={columns}
                        data={allCandy?.data?.data} />
                </>
                }

            </div>

        </div>
    );
};

export default Product;