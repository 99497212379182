import React, { useMemo, useState } from 'react'
import Navbar from '../../../components/Navbar'
import Loaders from '../../../components/Loaders'
import MaterialReactTable from 'material-react-table'
import { axiosPrivate } from '../../../api/axios';
import { useQuery } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export default function SalaryApproval() {
    const [approve, setApprove] = useState(0)

    const handleAttendance = () => {
        const res = axiosPrivate.get(`attendance/all`)
        return res;
    };

    const handleAttendanceIsApprove = (value) => {
        setApprove(value)
    }

    const handleTime = (value) => {
        //digit to hour and minute format
        const num = value;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const hour = rhours < 10 ? `0${rhours}` : rhours;
        const minute = rminutes < 10 ? `0${rminutes}` : rminutes;
        const format = hour > 0 ? `${hour} hour ${minute} min` : `${minute} min`;
        return format;

    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleApprove = (id, value) => {
        const res = axiosPrivate.put(`/attendance/approve`, {
            attendance_id: id,
            is_approved: value
        })
        refetch()
        return res;
    }


    const handleDelete = (id) => {
        const res = axiosPrivate.delete(`/attendance/delete/${id}`)
        refetch()
        return res;
    }

    const handleSalaryApprove = (id, value) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleApprove(id, value)
                refetch()
                swalWithBootstrapButtons.fire(
                    'Done!',
                    'Salary is approved',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',

                )
            }
        })
    }





    const handleAttendanceDelete = (id) => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id)
                refetch()
                swalWithBootstrapButtons.fire(
                    'Done!',
                    'Attendance is Deleted',
                    'success'
                )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelled',

                )
            }
        })
    }

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row?.employee?.name,//simple recommended way to define a column
                header: "Name",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => row?.attendance_date, //simple recommended way to define a column
                header: "Date",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => row?.check_in_time, //simple recommended way to define a column
                header: "Start Time",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => row?.check_out_time, //simple recommended way to define a column
                header: "End Time",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => handleTime(row?.pause_time), //simple recommended way to define a column
                header: "Lunch",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => handleTime(row?.work_minutes_total), //simple recommended way to define a column
                header: "Duration",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) => handleTime(row?.b2c_time), //simple recommended way to define a column
                header: "B2C",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => handleTime(row?.b2b_time), //simple recommended way to define a column
                header: "B2B",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },

            {
                accessorFn: (row) => handleTime(row?.prepacked_time), //simple recommended way to define a column
                header: "Prepacked",
                muiTableHeadCellProps: { sx: { color: "#6e4695" } },//custom props
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
            {
                accessorFn: (row) =>
                    <>

                        < div >
                            {(!row.is_approved && row?.check_out_time) && <button className='btn btn-green mx-2  fs-6' data-toggle="tooltip" data-placement="bottom" title="Approve"><i class="fa-solid fa-check" onClick={() => handleSalaryApprove(row.id, !row.is_approved)}></i> </button>}
                            {!row.is_approved && <Link to={`/admin/dashboard/salary-approval/edit/${row.id}`} className='btn btn-info mx-2 text-white fs-6' data-toggle="tooltip" data-placement="bottom" title="edit"><i class="fa-solid fa-pencil"></i> </Link>}
                            {!row.is_approved && <button className='btn btn-danger mx-2  fs-6' data-toggle="tooltip" data-placement="bottom" title="Delete"><i class="fa-solid fa-trash" onClick={() => handleAttendanceDelete(row.id)}></i> </button>}
                        </ div>
                    </>
                , //alternate way
                id: "Action", //id required if you use accessorFn instead of accessorKey
                header: "Action",
                Header: <i style={{ color: "orange" }}>Action</i>, //optional custom markup
                hide: true

            }
        ],
        [],
    );


    const { data: allAttendances = [], isLoading, refetch } = useQuery({
        queryKey: ['get-feedbacks'],
        queryFn: handleAttendance,
        cacheTime: 5000,
    })
    return (
        <div>
            <Navbar
                title="Salary Approval"
            />
            {
                isLoading && <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            }

            <div className='container my-5'>
                {allAttendances?.data?.data &&
                    <>
                        {
                            approve ? <button className='btn btn-primary  float-end' onClick={() => handleAttendanceIsApprove(0)}> Show Unapprove List</button>
                                : <button className='btn btn-info text-white  float-end' onClick={() => handleAttendanceIsApprove(1)}> Show approve List</button>
                        }
                    </>


                }

                {allAttendances?.data?.data &&
                    <>

                        <MaterialReactTable
                            columns={columns}
                            data={allAttendances?.data?.data.filter(item => {
                                if (!approve) {
                                    return !item?.is_approved
                                } else {
                                    return item?.is_approved
                                }
                            })}

                            initialState={{
                                columnVisibility: {
                                    Lunch: false,
                                    B2C: false,
                                    B2B: false,
                                    Prepacked: false,
                                    Action: !approve,
                                }
                            }}
                        />
                    </>
                }

            </div>

        </div>
    )
}
