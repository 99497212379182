import React, { useEffect, useState } from 'react'
import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

export default function ProductTypesUpdate() {
    const axiosPrivate = useAxiosPrivate();

    const params = useParams();
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = useForm();


    const handleProductType = () => {
        const res = axiosPrivate.get(`product-type/show/${params.id}`)
        return res;
    };



    const { data: productType } = useQuery({
        queryKey: ['get-ProductType'],
        queryFn: handleProductType,
        cacheTime: 5000,
    })



    useEffect(() => {
        if (productType?.data?.data) {
            Object.keys(productType.data.data).forEach((key) => {
                setValue(key, productType.data.data[key]);
            });
        }
    }, [productType, setValue])



    const postData = async (data) => {
        const makeData = {
            name: data.name,
            description: data.description,
            product_type_code: productType?.data?.data?.product_type_code,
            is_active: productType?.data?.data?.is_active,
        }

        const response = axiosPrivate.put(`product-type/update/${params.id}`, makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/product-types'
                }, 1000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title="Update a Product Types"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    placeholder="Name"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    {...register("name")}
                                    error={!!errors?.from}
                                    helperText={errors.from?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    placeholder="Enter Description"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    {...register("description")}
                                    error={Boolean(errors?.description)}
                                    helperText={errors.description ? errors.to?.description : ""}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
