import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import Navbar from '../../../components/Navbar';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

export default function AddNewCoupon() {
  const axiosPrivate = useAxiosPrivate();
  const [isCodeUnique, setIsCodeUnique] = useState(true);
  const [prefix, setPrefix] = useState('+45')
  const [country, setCountry] = useState([])
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const checkCodeUnique = async (value) => {
    try {
      const response = await axiosPrivate.post("coupon/check-unique", { "code": value });

      if (response.data.success === false) {

        return false;
      }
      return true;
    } catch (error) {

      console.error(error);
    }
  };

  useEffect(() => {
    getAllCountry()

  }, [])

  const getAllCountry = () => {
    axiosPrivate.get('country/all').then((response) => {
      setCountry(response.data.data)
    }).catch((error) => {
      console.error(error)
    })
  }

  const handleCodeBlur = async (event) => {
    const code = event.target.value;
    setCode(code)
    const isUnique = await checkCodeUnique(code);
    setIsCodeUnique(isUnique);
  };

  const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm();

  const postData = async (data) => {
    const response = axiosPrivate.post('coupon/create-coupon-web-admin', {
      code: data.code,
      value: data.value,
      coupon_type: data.coupon_type,
      user_phone: prefix + data.user_phone,
      value_type: (data.coupon_type == 'individual' && 'fixed_amount') || (data.coupon_type == 'campaign' && 'percentage') || (data.coupon_type == 'phone' && 'fixed_amount')
    });
    return response;
  }

  const { mutate } = useMutation(postData, {
    onSuccess: (successData) => {
      if (successData?.data?.success) {
        setTimeout(() => {
          window.location.href = '/admin/dashboard/coupons'
        }, 3000)
      }
    },
    onError: (error) => {
      console.error(error)
      setIsLoading(false)
    }
  })

  const onSubmit = (data) => {
    setIsLoading(true)
    mutate(data);
  }
  return (
    <>
      <Navbar
        title="Create a new Coupon"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='container my-5'>
          <div className='card'>
            <Grid container spacing={2}>

              <Grid item xs={6}>
                <TextField
                  id="code"
                  label="Code"
                  variant="outlined"
                  placeholder="Enter Code"
                  margin="normal"
                  fullWidth={true}
                  {...register("code")}
                  onBlur={handleCodeBlur} // <-- Add onBlur event handler
                  error={isCodeUnique === false} // <-- Set error state based on isCodeUnique
                  helperText={code && (isCodeUnique === false ? <p>Code is NOT available</p> : <p className='text-success'>Code is available </p>)}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="value"
                  label="Value"
                  variant="outlined"
                  placeholder="Enter Discount %"
                  margin="normal"
                  fullWidth={true}
                  {...register("value")}
                  error={Boolean(errors?.value)}
                  helperText={errors.value ? errors.value?.message : ""}
                />

              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel id="demo-simple-select-label">Coupon type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="coupon_type"
                    {...register("coupon_type")}
                    fullWidth
                    value={watch("coupon_type") || []}
                    label="Coupon type"
                    onChange={(event) => {
                      setValue("coupon_type", event.target.value)
                    }}

                  >
                    <MenuItem value="individual">Individual (Fixed Amount)</MenuItem>
                    <MenuItem value="campaign">Campaign (percentage)</MenuItem>
                    <MenuItem value="phone">Phone (Fixed Amount)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {
                getValues('coupon_type') == 'phone' &&
                <Grid container item xs={6}>
                  <Grid item xs={3}>
                    {/* Country Prefix Dropdown */}
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="country-prefix-label">Country Prefix</InputLabel>
                      <Select
                        labelId="country-prefix-label"
                        id="country-prefix"
                        onChange={(e) => setPrefix(e.target.value)}
                        label="Country Prefix"
                      >
                        {country.map((item, index) => {
                          return item.is_active == true && <MenuItem key={index} value={item.phone_prefix}>{item.phone_prefix}</MenuItem>
                        }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9}>
                    {/* User Phone TextField */}
                    <TextField
                      id="user_phone"
                      label="User Phone"
                      variant="outlined"
                      placeholder="Phone"
                      fullWidth={true}
                      {...register("user_phone")}
                      error={Boolean(errors?.user_phone)}
                      helperText={errors.user_phone ? errors.value?.message : ""}
                    />
                  </Grid>
                </Grid>

              }

              <Grid item xs={12}>
                <button
                  className='btn btn-success text-white'
                  type="submit"
                  disabled={!isCodeUnique || isLoading} // <-- Disable button if code is not unique
                >Submit</button>

              </Grid>
            </Grid>


          </div>

        </div>
      </form >
    </>
  )
}
