import React, { useState } from 'react'
import Navbar from '../../../components/Navbar'
import salary from '../../../assets/search.gif'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Loaders from '../../../components/Loaders';

export default function SalaryCalculation() {

    const [result, setResult] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [notApprove, setNotApprove] = useState(false)

    const postData = async (data) => {
        const response = axiosPrivate.get(`salary/calculate?start_date=${data.start_date}&end_date=${data.end_date}&is_payment_clear=${data.is_payment_clear}`)
        return response;
    }

    const { mutate, isLoading } = useMutation(postData, {
        onSuccess: (successData) => {
            setResult(successData?.data?.data?.payment);
            setNotApprove(successData?.data?.data?.not_approve)
        }
    })

    const handleMakePayment = async (attendance_indexes, index) => {

        const response = await axiosPrivate.post(`salary/make-payment`, {
            attendance_indexes: attendance_indexes
        })

        if (response.data.success) {
            const updatedResult = [...result];
            updatedResult[index].is_paid = true;
            setResult(updatedResult);
        }
        return response.data.success;
    }


    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        mutate(data);
    };
    return (
        <>
            <Navbar
                title="Salary Calculation"
            />

            <div className='container'>
                <div className="card m-5">
                    <div className="card-body">
                        <div className=' d-flex justify-content-between'>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='d-flex'>
                                    <div className='m-3'>
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" {...register("start_date")} placeholder="Start Date" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>

                                    <div className='m-3'>
                                        <label>End Date</label>
                                        <input type="date" className="form-control" {...register("end_date")} placeholder="End Date" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>

                                    <div className='m-3'>
                                        <label>Payment Status</label>
                                        <select className="form-select" name='is_payment_clear' {...register("is_payment_clear")} >
                                            <option value={0}>Unpaid</option>
                                            <option value={1} >Paid</option>
                                        </select>

                                    </div>

                                    <button type="submit" className="btn btn-primary  searchButton">Search</button>
                                </div>
                            </form>


                            <div>
                                <img src={salary} className='salarySearch' alt='searchImage' />
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            {isLoading && <div className="d-flex justify-content-center align-items-center">
                <Loaders />
            </div>}

            {result?.length > 0 && <div className='container'>
                <div className="card m-5">
                    <div className="card-body">
                        {
                            (result[0]?.is_payment_clear == 0 && notApprove) &&
                            <h4 className='text-danger text-start my-3'> ATTENTION: There are registered shift, that has NOT been approved in the time span</h4>
                        }
                        <div className=' d-flex justify-content-between'>
                            <h5 className='card-title'>Total {result?.length} Employee Found!!</h5>
                        </div>
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Employee Name</th>
                                        <th scope="col">Total Hour</th>
                                        <th scope="col">Salary/hr</th>
                                        <th scope="col">Total Packed</th>
                                        <th scope="col">Cost/order</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        result?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.employee.name}</td>
                                                    <td>{item.total_minutes_worked}</td>
                                                    <td>{item.salary}</td>
                                                    <td>{item.total_packed}</td>
                                                    <td>{item.avg_salary_per_order.toFixed(2)}</td>
                                                    <td>
                                                        {
                                                            (item.is_payment_clear == 1 || item.is_paid) ?
                                                                <button className='btn btn-success' disabled>Paid</button> :
                                                                <button
                                                                    className='btn btn-info'
                                                                    onClick={() => handleMakePayment(item.attendance_indexes, index)}
                                                                >
                                                                    Make Payment
                                                                </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
