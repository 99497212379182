import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

export default function CreateOrderType() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const [orderTypeImageEn, setOrderTypeImageEn] = useState(null);
    const [orderTypeImageDa, setOrderTypeImageDa] = useState(null);
    const [basketImage, setBasketImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const postData = async (data) => {
        const makeData = {
            label: {
                en: data.label_en,
                da: data.label_da
            },
            description: {
                en: data.description_en,
                da: data.description_da
            },
            order_type: data.order_type,
            is_active: data.is_active,
            images: {
                en: orderTypeImageEn ? orderTypeImageEn : null,
                da: orderTypeImageDa ? orderTypeImageDa : null
            },
            basket_image: basketImage ? basketImage : null
        }

        const response = axiosPrivate.post(`order-type/create-new`, makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/order-types'
                }, 3000)
            }
        },
        onError: (error) => {
            setIsLoading(false)
        }
    })

    const handleFile = (event, value) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();


        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            if (value === "da") {
                setOrderTypeImageDa(reader?.result);
            } else if (value === "en") {
                setOrderTypeImageEn(reader?.result);
            }
            else if (value === "basket") {
                setBasketImage(reader?.result);
            }
        };
    }


    const onSubmit = (data) => {
        setIsLoading(true)
        mutate(data);
    }




    return (
        <>
            <Navbar
                title="Create a OrderType"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="label (En)"
                                    label="Label (En)"
                                    variant="outlined"
                                    placeholder="Enter Label (En)"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("label_en")}
                                    error={!!errors?.label}
                                    helperText={errors.label?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="label (Da)"
                                    label="Label (Da)"
                                    variant="outlined"
                                    placeholder="Enter Label (Da)"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("label_da")}
                                    error={!!errors?.label}
                                    helperText={errors.label?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Order Types</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("order_type")}
                                        value={watch("order_type") || ""}
                                        label="Order Type"
                                        onChange={(event) => {
                                            setValue("order_type", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="pick_and_mix">Pick and Mix</MenuItem>
                                        <MenuItem value="invited_bag">Invite friend</MenuItem>
                                        <MenuItem value="gift">Gift Items</MenuItem>
                                        <MenuItem value="other_product">Other Products</MenuItem>
                                        <MenuItem value="gift_card">Gift Card</MenuItem>
                                        <MenuItem value="father_day">Father Day</MenuItem>
                                    </Select> </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("is_active")}
                                        value={watch("is_active") || ""}
                                        label="Status"
                                        onChange={(event) => {
                                            setValue("is_active", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}> Active</MenuItem>
                                        <MenuItem value={0}> Inactive</MenuItem>
                                    </Select> </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <>
                                    <Typography textAlign="left" >
                                        {errors.order_description_en ? (
                                            <Typography color="error">Description  (En)</Typography>
                                        )
                                            : <Typography>Description (En)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} onChange={(e) => setValue('description_en', e.target.value)} />

                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <>
                                    <Typography textAlign="left" >
                                        {errors.order_description_da ? (
                                            <Typography color="error">Description  (Da)</Typography>
                                        )
                                            : <Typography>Description (Da)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} onChange={(e) => setValue('description_da', e.target.value)} />
                                </>
                            </Grid>


                            <Grid item xs={6}>
                                <img src={orderTypeImageEn} alt='' height="100px" />
                            </Grid>

                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Order Type Image (En) (110 * 110)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "en")} />
                            </Grid>

                            <Grid item xs={6}>
                                <img src={orderTypeImageDa} alt='' height="100px" />
                            </Grid>
                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Order Type Image (Da) (110 * 110)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "da")} />
                            </Grid>

                            <Grid item xs={6}>
                                <img src={basketImage} alt='' height="100px" />
                            </Grid>

                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Basket Image (75 * 75) </label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "basket")} />
                            </Grid>


                            <Grid item xs={6}></Grid>


                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    disabled={isLoading}
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
