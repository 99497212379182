import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function RefundModalComponent({ row, refetch }) {

    const [refundType, setRefundType] = useState('');
    const { register } = useForm();
    const axiosPrivate = useAxiosPrivate();

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        for (const item of formData) {
            const [key, value] = item
            const makeData = {
                ref: row.order_tracking_id,
                idempotency_key: row.uuid,
                payment_id: row.qp_payment_id,
                amount: refundType === 'full' ? row.total : Number(value),
            }
            mutate(makeData)
        }
    };
    const postData = async (data) => {
        const response = axiosPrivate.post('/refund/mobilepay/payment', data);
        return response;
    }


    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            refetch()

            if (successData?.data?.success == false) {
                alert('Sorry, we could not proceed with refund as the captured amount today is less than the amount you want to refund. Wait and refund, when total captured amount is higher')
            }
        }
    })
    const handleRefundTypeChange = (event) => {
        const selectedValue = event.target.value;
        setRefundType(selectedValue);
    };

    return (
        <>
            <div>
                <>
                    {row.shopify_order_id && <a
                        href={`https://admin.shopify.com/store/slikekspressen/orders/${row.shopify_order_id}`}
                        target="_blank"
                        rel="noreferrer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Go to shopify"
                        className="btn btn-success bg-shopify-color mx-2 text-white fs-6"
                    >
                        <i className="fab fa-shopify"></i>
                    </a>}

                    {((row.payment_method === 'mobilepay' && row.order_tracking_link && row.refund_amount === null) || (row.payment_method === 'mobilepay' && row.payment_status === 'captured' && row.refund_amount === null)) &&
                        <button
                            title="Refund"
                            data-bs-toggle="modal"
                            data-bs-target={`#refundModal-${row.order_id}`}
                            className="btn btn-yellow mx-2  fs-6"
                        >
                            <i class="fa-solid fa-money-bill-transfer"></i>
                        </button>}

                    <div class="modal fade" id={`refundModal-${row.order_id}`} tabIndex="-1" aria-labelledby={`refundModalLabel-${row.order_id}`} aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id={`refundModalLabel-${row.order_id}`}>Refund</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={onSubmit}>
                                        <select className="form-select form-select-lg mb-3" onChange={(e) => { handleRefundTypeChange(e) }} aria-label=".form-select-lg example">
                                            <option selected hidden>Select Refund Type</option>
                                            <option value="full">Full Amount</option>
                                            <option value="partial">Partial Amount</option>
                                        </select>


                                        {refundType === 'partial' ? (
                                            <div className="form-group">
                                                <label htmlFor={`partialAmountInput-${row.order_id}`}>Partial Amount</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id={`partialAmountInput-${row.order_id}`}
                                                    {...register('amount',
                                                        {
                                                            required: true,
                                                            pattern: {
                                                                value: /^[0-9]+(\.[0-9]+)?$/, // Regular expression for float numbers
                                                                message: "Please enter a valid number"
                                                            },
                                                            max: row.total,
                                                            min: 1,


                                                        }
                                                    )}
                                                    min={1}
                                                    required
                                                    max={row.total}
                                                    step={0.01}
                                                    placeholder="Enter Partial Amount"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <input type='hidden' {...register('amount', row.total)} />
                                            </>
                                        )}


                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="submit" data-bs-dismiss="modal" disabled={!refundType} class="btn btn-primary">Save changes</button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </>
            </div >


        </>
    )
}
const RefundModal = React.memo(RefundModalComponent)
export default RefundModal
