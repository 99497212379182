import Navbar from '../../../../components/Navbar'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function UpdateCountry() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const params = useParams();
    const [flag, setFlag] = useState(null);
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const postData = async (data) => {
        const makeData = {
            name: data.name,
            code: data.code,
            currency_code: data.currency_code,
            phone_prefix: data.phone_prefix,
            language: data.language,
            app_language: data.app_language,
            symbol: data.symbol,
            decimal_digits: data.decimal_digits,
            thousand_separator: data.thousand_separator,
            fraction_separator: ",",
            symbol_spacing: 1,
            is_def_based_currency: 1,
            exchange_rate: 0,
            flag: flag ? flag : null,
            maxLength: data.maxLength,
            minLength: data.minLength
        }

        const response = axiosPrivate.put(`country/update/${params.id}`, makeData);
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/countries'
                }, 3000)
            }
        },
        onError: (error) => {
            setIsLoading(false)
        }
    })

    const handleCountry = () => {
        const res = axiosPrivate.get(`country/show/${params.id}`)
        return res;
    }

    const handleFile = (event) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();


        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setFlag(reader?.result);
        };
    }


    const onSubmit = (data) => {
        setIsLoading(true)
        mutate(data);
    }

    const { data: country } = useQuery({
        queryKey: ['get-country'],
        queryFn: handleCountry
    })


    useEffect(() => {
        if (country?.data?.data) {
            Object.keys(country.data.data).forEach((key) => {
                setValue(key, country.data.data[key]);
            });

            setValue('symbol', country?.data?.data?.currency.symbol)
            setValue('decimal_digits', country?.data?.data?.currency.decimal_digits)
            setValue('thousand_separator', country?.data.data?.currency.thousand_separator)
            setImage(country?.data?.data?.flag)

        }
    }, [country, setValue])


    return (
        <>
            <Navbar
                title="Update a Country"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    placeholder="Enter Country Name"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("name")}
                                    error={!!errors?.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="code"
                                    label="code"
                                    variant="outlined"
                                    placeholder="Enter code"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("code")}
                                    error={Boolean(errors?.code)}
                                    helperText={errors.code ? errors.code?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="currency_code"
                                    label="Currency code"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter Currency code"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("currency_code")}
                                    error={Boolean(errors?.currency_code)}
                                    helperText={errors.currency_code ? errors.currency_code?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="phone_prefix"
                                    label="Phone Prefix"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter Phone Prefix"
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("phone_prefix")}
                                    error={Boolean(errors?.phone_prefix)}
                                    helperText={errors.phone_prefix ? errors.phone_prefix?.message : ""}
                                />

                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="maxLength"
                                    label="Phone Number Max Length"
                                    variant="outlined"
                                    placeholder="Phone Number Max Length"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    {...register("maxLength")}
                                    error={Boolean(errors?.maxLength)}
                                    helperText={errors.maxLength ? errors.maxLength?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="minLength"
                                    label="Phone Number Min Length"
                                    variant="outlined"
                                    placeholder="Phone Number Min Length"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("minLength")}
                                    error={Boolean(errors?.minLength)}
                                    helperText={errors.minLength ? errors.minLength?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="language"
                                    label="Language"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter Language"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("language")}
                                    error={Boolean(errors?.language)}
                                    helperText={errors.language ? errors.language?.message : ""}
                                />

                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">App Language</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("app_language")}
                                        value={watch("app_language") || ""}
                                        label="Order Type"
                                        onChange={(event) => {
                                            setValue("app_language", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="da">Danish</MenuItem>
                                    </Select> </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="symbol"
                                    label="Currency Symbol"
                                    variant="outlined"
                                    placeholder="Enter symbol"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    {...register("symbol")}
                                    error={Boolean(errors?.da_text)}
                                    helperText={errors.da_text ? errors.da_text?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="decimal_digits"
                                    label="Decimal Digits"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Decimal Digits"
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("decimal_digits")}
                                    error={Boolean(errors?.decimal_digits)}
                                    helperText={errors.decimal_digits ? errors.decimal_digits?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="thousand_separator"
                                    label="Thousand separator"
                                    variant="outlined"
                                    placeholder="Thousand separato"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    {...register("thousand_separator")}
                                    error={Boolean(errors?.thousand_separator)}
                                    helperText={errors.thousand_separator ? errors.thousand_separator?.message : ""}
                                />

                            </Grid>

                            <Grid item xs={6}>
                                <img src={flag ? flag : image} alt='' height="100px" />
                            </Grid>



                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Flag Image</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={handleFile} />
                            </Grid>

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    disabled={isLoading}
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
